#link {
	text-decoration: none;
	color: rgba(0, 0, 0, 0.55);
}
a {
	text-decoration: none;
	color: rgba(0, 0, 0, 0.55);
}
a:hover {
	color: rgba(0, 0, 0, 0.55);
}
