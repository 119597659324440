.text-gray {
	color: #aeaeae;
}
a.text-gray:hover {
	color: #aeaeae;
}
.text-line-none {
	text-decoration: none;
}
.text-dark-red {
	color: #930909;
	text-decoration: none;
}

.text-dark-red:hover {
	color: #810909;
}
.list-studi {
	padding-left: 18px;
}
.separator {
	display: flex;
	align-items: center;
}

.separator .line {
	flex: 1;
	height: 3px;
	background-color: #930909;
	margin-left: 1rem;
}

.btn.btn-base {
	background-color: #930909;
	color: #ffffff;
}
.btn.btn-base:hover {
	background-color: #810909;
	color: #ffffff;
}
a.btn.btn-base:hover {
	background-color: #810909;
	color: #ffffff;
}
.banner {
	width: 100%;
	background-size: cover;
}
.vl {
	border-left: 4px solid #930909;
	height: 250px;
}
@media screen and (max-width: 1000px) {
	.vl {
		display: none;
	}
}
.bg-base {
	background-color: #930909;
}
.bg-gradasi {
	background-image: linear-gradient(to left, #930909, rgb(218, 6, 6));
}
.akademik-card {
	text-decoration: none;
}

Link {
	color: black;
	text-decoration: none;
}

.card-left {
	border-left: 3px solid white;
	height: 5rem;
}

.download {
	text-decoration: none;
	color: #3a3838;
}
.download:hover {
	color: #930909;
}

#link {
	text-decoration: none;
	color: #000000 !important;
}
#link:hover {
	color: #930909 !important;
}
.left-line {
	border-left: 3px solid #930909;
	padding-left: 10px;
}

.medium {
	font-weight: 600;
}
